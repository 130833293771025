import {json, type MetaFunction, type LoaderArgs} from '@shopify/remix-oxygen';
import {useLoaderData} from '@remix-run/react';
import type {
  Collection,
  CollectionConnection,
} from '@shopify/hydrogen/storefront-api-types';
import {
  Grid,
  Heading,
  Section,
  Link,
  Pagination,
  getPaginationVariables,
  Button,
} from '~/components';
import {getImageLoadingPriority} from '~/lib/const';
import {useState} from 'react';

const PAGINATION_SIZE = 20;

export const handle = {
  seo: {
    title: 'All Collections',
  },
};

export const loader = async ({request, context: {storefront}}: LoaderArgs) => {
  const variables = getPaginationVariables(request, PAGINATION_SIZE);
  const {collections} = await storefront.query<{
    collections: CollectionConnection;
  }>(COLLECTIONS_QUERY, {
    variables: {
      ...variables,
      country: storefront.i18n.country,
      language: storefront.i18n.language,
    },
  });

  return json({collections});
};

export const meta: MetaFunction = () => {
  return {
    title: 'All Collections',
  };
};

export default function Collections() {
  const [displayAll, setDisplayAll] = useState(3);
  const {collections} = useLoaderData<typeof loader>();
  const lastCollection = collections.nodes.slice(-1);

  function displayAllCollections() {
    setDisplayAll(-1);
  }

  return (
    <>
      <div className="relative flex flex-col">
        <div className="border-b border-black/50 py-9">
          <h2 className="title text-3xl md:text-5xl text-center uppercase">
            Our Collections
          </h2>
        </div>
        <Section>
          <div className="flex flex-col gap-10 md:gap-24 py-10">
            <div className="">
              <div className="w-fit mx-auto flex flex-col gap-5 items-center hover:underline">
                <Link to={`/collections/${lastCollection[0].handle}`}>
                  <img
                    className="w-full md:w-[900px] rounded rounded-[50px] max-h-[450px] object-cover"
                    src={lastCollection[0].image?.url}
                    alt=""
                  />
                </Link>
                <Link
                  className="title text-2xl"
                  to={`/collections/${lastCollection[0].handle}`}
                >
                  {lastCollection[0].title}
                </Link>
              </div>
            </div>
            <div>
              <div className="flex flex-wrap justify-around gap-10 w-fit w-auto mx-auto">
                {collections.nodes.slice(0, displayAll).map((collection, i) => (
                  <CollectionCard
                    collection={collection}
                    key={collection.id}
                    loading={getImageLoadingPriority(i, 2)}
                  />
                ))}
              </div>

              {displayAll == 3 ? (
                <div className="pt-14 md:pt-24 flex flex-col items-center justify-center">
                  <button
                    onClick={() => displayAllCollections()}
                    className="w-fit w-auto mx-auto pb-2 border-b border-black/50 title md:text-2xl px-5"
                  >
                    View all collections
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Section>
      </div>
    </>
  );
}

function CollectionCard({
  collection,
  loading,
}: {
  collection: Collection;
  loading?: HTMLImageElement['loading'];
}) {
  return (
    <Link
      to={`/collections/${collection.handle}`}
      className="grid gap-3 hover:underline max-w-[400px]"
    >
      <div className="card-image bg-primary/5 aspect-[3/2] max-w-[400px] max-h-[270px] object-cover">
        {collection?.image && (
          <img
            className="rounded rounded-[50px] max-w-[400px] max-h-[270px] object-cover"
            alt={collection.title}
            src={collection.image.url}
            height={400}
            sizes="(max-width: 32em) 100vw, 33vw"
            width={600}
            loading={loading}
          />
        )}
      </div>
      <Heading
        as="h3"
        size="copy"
        className="title text-xl text-center font-bold"
      >
        {collection.title}
      </Heading>
    </Link>
  );
}

const COLLECTIONS_QUERY = `#graphql
  query Collections(
    $country: CountryCode
    $language: LanguageCode
    $first: Int
    $last: Int
    $startCursor: String
    $endCursor: String
  ) @inContext(country: $country, language: $language) {
    collections(first: $first, last: $last, before: $startCursor, after: $endCursor) {
      nodes {
        id
        title
        description
        handle
        seo {
          description
          title
        }
        image {
          id
          url
          width
          height
          altText
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;
